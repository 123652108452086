import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Truth. Health. Wealth. In that order.`}</p>
    <p>{`An almanack for BalajiS, who correctly foresaw the rise of crypto and the covid pandemic, amongst other things.`}</p>
    <p>{`He is an Engineer, Investor, Futurist.`}</p>
    <h2 {...{
      "id": "this-website",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#this-website",
        "aria-label": "this website permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`This website`}</h2>
    <p>{`It's an effort to systematically round up and organize the main threads of Balaji's ideas. `}</p>
    <p>{`We organize these ideas into "concepts" and add original sources in the mix so that readers can understand the context. `}</p>
    <br />
    <h2 {...{
      "id": "contributing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#contributing",
        "aria-label": "contributing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contributing`}</h2>
    <p>{`We are actively looking for contributors for both the website and it's content. This effort started at July 8th, 2021.`}</p>
    <p>{`Visit the `}<a parentName="p" {...{
        "href": "https://github.com/OdysLam/balajis-almanack"
      }}>{`GitHub repository`}</a>{` for more information.`}</p>
    <p>{`If you want to improve a particular page of this website, just click on "Edit this page on GitHub". You will find it at the end of every page. `}</p>
    <h2 {...{
      "id": "contributors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#contributors",
        "aria-label": "contributors permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contributors`}</h2>
    <a {...{
      "href": "https://github.com/odyslam/balajis-almanack/graphs/contributors"
    }}>{`
  `}<img parentName="a" {...{
        "src": "https://contrib.rocks/image?repo=odyslam/balajis-almanack"
      }}></img>
    </a>
    <p>{`Original Idea by `}<a parentName="p" {...{
        "href": "https://twitter.com/dickiebush/status/1412912753052844035"
      }}>{`Dickie Bush`}</a>{`. `}</p>
    <p>{`First content aggregation and website curation by [`}<a parentName="p" {...{
        "href": "https://twitter.com/grant_nissly/status/1409916606214455307"
      }}>{`Grant Nissly`}</a>{`].`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      